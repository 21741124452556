<template>
  <div class="p-5 flex flex-column">
    <div class="tab-container">
      <TabViewHeaders v-if="!mobile" :headers="headers" @change="onTabHeadersChange" :root-page="'/securemessaging'"/>
      <div v-if="mobile">
        <TabViewDropdown :headers="headers" @change="onTabHeadersChange" :root-page="'/securemessaging'"/>
        <spacer-h/>
      </div>
      <router-view></router-view>
      <HelpCard mode="messaging" class="pt-5" />
    </div>
  </div>
</template>

<script>
import SpacerH from '@/components/common/layout/SpacerH.vue'
import TabViewDropdown from "@/components/nav/TabViewDropdown";
import TabViewHeaders from "@/components/nav/TabViewHeaders";
import {computed, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import HelpCard from "@/components/common/HelpCard";

export default {
  name: "SecureMessaging",
  components: {
    TabViewDropdown,
    TabViewHeaders,
    SpacerH,
    HelpCard
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const headers = computed(() => store.getters.getTabHeaders('securemessaging'))
    const mobile = ref(false)
    const screenSize = computed(() => store.getters.getTabView)

    const onTabHeadersChange = (payload) => {
      store.dispatch('setActiveHeader', {page: 'securemessaging', headers: payload})
    }

    const isRootPage = computed(() => {
      return route.path === '/securemessaging'
    })

    onMounted(() => {
      if (isRootPage.value === true) {
        store.dispatch('setMailboxType', 'inbox')
        router.push('/securemessaging/inbox')
      }
      mobile.value = screenSize.value === 'mobile'
    })

    watch(screenSize, (val) => {
      mobile.value = val === 'mobile'
    })

    return {
      headers,
      mobile,
      onTabHeadersChange,
    }
  }
}
</script>

<style scoped>

</style>
